<template>
  <view-item title="收款">
    <template #operation>
      <en-dropdown @command="operation.add.command">
        <en-button type="primary">创建单据</en-button>
        <template #dropdown>
          <en-dropdown-item command="other">其他应收</en-dropdown-item>
        </template>
      </en-dropdown>
      <en-button v-if="form.data.status?.code && ['C'].includes(form.data.status.code)" @click="operation.batch.click" type="primary">
        批量收款
      </en-button>
      <button-ajax
        v-if="form.data.status?.code && ['P', 'C'].includes(form.data.status.code)"
        :method="operation.submit.click"
        :disabled="form.disabled"
      >
        收款
      </button-ajax>
      <button-ajax v-if="form.data.status?.code === 'F'" :method="operation.reverse.click">取消收银</button-ajax>
      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="invioce" v-if="form.data.type && !['POLRB', 'OTH '].includes(form.data.type.code)">开票</en-dropdown-item>
          <en-dropdown-item
            command="credit"
            v-if="form.data.status?.code === 'P' && form.data.type && ['SAL', 'SRV', 'POL', 'TOPUP', 'MCBORD'].includes(form.data.type.code)"
            >挂账</en-dropdown-item
          >
          <en-dropdown-item
            command="cancelCredit"
            v-if="form.data.status?.code === 'C' && form.data.type && ['SAL', 'SRV', 'POL', 'TOPUP', 'MCBORD'].includes(form.data.type.code)"
            >取消挂账</en-dropdown-item
          >
          <en-dropdown-item command="logs">单据历史</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        :ajax="{ action: 'GET /enocloud/settlement/receivable/query' }"
        :props="{ start: 'settlementStartDate', end: 'settlementEndDate' }"
        @expand-click="receivableDialog.visible = true"
        @row-click="manifest.row.click"
      >
        <en-table-column label="单号" prop="serialNo"></en-table-column>
        <en-table-column label="车牌" prop="plateNo"></en-table-column>

        <template #form="{ data }">
          <en-form-item label="收款搜索">
            <en-input v-model="data.quickSearch" placeholder="收款对象/业务单号/收款单号"></en-input>
          </en-form-item>
          <en-form-item label="单号">
            <en-input v-model="data.documentSerialNo"></en-input>
          </en-form-item>
          <en-form-item label="车牌号">
            <en-input v-model="data.plateNo"></en-input>
          </en-form-item>
          <en-form-item label="结算日期">
            <en-date-picker v-model:start="data.settlementStartDate" v-model:end="data.settlementEndDate" type="daterange"></en-date-picker>
          </en-form-item>
          <en-form-item label="结清日期">
            <en-date-picker v-model:start="data.settleStartDate" v-model:end="data.settleEndDate" type="daterange"></en-date-picker>
          </en-form-item>

          <en-form-item label="状态">
            <select-maintain
              v-model="data.statusCode"
              :ajax="{
                action: 'GET /enocloud/common/lookup/:lookupType',
                params: (params) => (params.paths = ['RVBSTAT'])
              }"
              :props="{ label: 'message', value: 'code' }"
              value-key="code"
              remote
              multiple
              class="w-full"
            ></select-maintain>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <en-collapse>
          <en-collapse-item>
            <template #title>
              <div class="flex items-center gap-6">
                <span class="text-#5469D4FF">{{ form.data.serialNo }}</span>
                <span v-if="form.data.type?.code === 'SRV'">{{ form.data.service?.vehicle?.plateNo }}</span>
                <span>{{ form.data.payer?.name }}</span>
                <span class="text-red">(欠款{{ formatMoney(form.data?.amount) }})</span>
                <span>{{ form.data.type?.message }}</span>
                <span v-if="form.data.type?.code === 'SRV'">{{ form.data.preparedBy?.name }}</span>
                <span v-if="form.data.type?.code === 'SRV'">{{ formatDate(form.data.preparedDatetime) }}（结算）</span>
              </div>
            </template>

            <en-form v-if="form.data.type?.code === 'SRV'" label-position="right" class="grid grid-cols-3">
              <en-form-item label="整单备注:">{{ form.data.service?.comment }}</en-form-item>
              <en-form-item label="结算备注:">{{ form.data.service?.settlementComment }}</en-form-item>
            </en-form>
          </en-collapse-item>
        </en-collapse>

        <div v-if="form.data.type?.code === 'SRV'" class="flex items-center gap-6 text-xs text-neutral-500">
          <div class="flex flex-col items-center gap-2">
            <span>工单金额</span>
            <span>{{ formatMoney(form.summary.service.totalAmount) }}</span>
          </div>
          <div class="flex flex-col items-center gap-2">
            <span>折扣优惠</span>
            <span>{{ formatMoney(form.summary.service.discountAmount) }}</span>
          </div>
          <div class="flex flex-col items-center gap-2">
            <span>卡券抵扣</span>
            <span>{{ formatMoney(form.summary.service.discountByCouponAmount) }}</span>
          </div>
          <div class="flex flex-col items-center gap-2">
            <span>余额抵扣</span>
            <span>{{ formatMoney(form.summary.service.discountByRemainAmount) }}</span>
          </div>
          <div class="flex flex-col items-center gap-2">
            <span>定金抵扣</span>
            <span>{{ formatMoney(form.summary.service.preAmount) }}</span>
          </div>
          <div class="flex flex-col items-center gap-2">
            <span>工单减免</span>
            <span>{{ formatMoney(form.summary.service.freeAmount) }}</span>
          </div>
          <div class="flex flex-col items-center gap-2">
            <span>应收金额</span>
            <span class="text-red"
              >{{
                formatMoney(
                  Math.floor(
                    form.summary.service.totalAmount -
                      form.summary.service.discountAmount -
                      form.summary.service.freeAmount -
                      form.summary.service.discountByCouponAmount
                  )
                )
              }}
              （抹零金额：{{ (form.summary.service.totalAmount - form.summary.service.discountAmount).toFixed(2).split('.')[1] * 0.01 }}）
            </span>
          </div>
        </div>
      </en-card>

      <en-card v-loading="form.loading">
        <div class="flex items-center gap-4 text-sm">
          <span>应收金额:{{ formatMoney(form.summary.amount) }}</span>
          <span>已收金额:{{ formatMoney(form.summary.receivedAmount) }}</span>
          <span
            >未收金额:{{
              formatMoney(
                Math.floor(
                  form.summary.unReceivedAmount -
                    form.summary.service.discountAmount -
                    form.summary.service.freeAmount -
                    form.summary.service.discountByCouponAmount
                )
              )
            }}</span
          >
        </div>
        <en-form class="grid grid-cols-3 gap-x-6 mt-5" v-if="form.data.status?.code !== 'F'">
          <en-form-item label="金额">
            <en-input-number
              :model-value="receipt.data.receiptReceivables[0]?.amount"
              :min="0"
              :max="form.summary.unReceivedAmount"
              class="w-full"
              @change="receipt.amount.change"
            ></en-input-number>
          </en-form-item>

          <en-form-item>
            <template #label>
              <div class="items-center gap-1">
                <span>定金抵扣</span>
                <span class="text-xs text-primary ml-60">定金余额{{ formatMoney(form.data.receiptAdvance) }}</span>
              </div>
            </template>
            <en-input-number
              :model-value="receipt.data.receiptReceivables[0]?.receiptAdvanceDeposit"
              class="w-full"
              :disabled="form.moneyDisabled"
              :max="100"
              :min="0"
            ></en-input-number>
          </en-form-item>

          <en-form-item v-if="form.data.type && ['SRV', 'SAL'].includes(form.data.type.code)">
            <template #label>
              <div class="items-center gap-1">
                <span>优惠减免</span>
                <span
                  text
                  style="cursor: pointer"
                  class="text-xs text-primary ml-70"
                  v-if="
                    form.summary.unReceivedAmount -
                      form.summary.service.discountAmount -
                      receipt.data.receiptReceivables[0]?.amount -
                      form.summary.service.preAmount >
                    0
                  "
                  @click="form.remain.click"
                  >剩余减免</span
                >
              </div>
            </template>
            <en-input-number
              :model-value="parseInt(receipt.data.receiptReceivables[0]?.receivable?.adjustingAmount)"
              class="w-full"
              @change="receipt.adjustingAmount.change"
              :min="0"
              :max="
                form.summary.unReceivedAmount -
                form.summary.service.discountAmount -
                receipt.data.receiptReceivables[0]?.amount -
                form.summary.service.preAmount
              "
              :precision="2"
              :disabled="form.remainingDisabled"
            ></en-input-number>
          </en-form-item>

          <en-form-item label="收款方式">
            <select-maintain
              v-model="receipt.data.paymentMethod"
              :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['PAMTMTD']) }"
              :props="{ label: 'name', value: 'name' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="收银时间">
            <en-date-picker v-model="receipt.data.businessDatetime" format="YYYY-MM-DD" class="w-full"></en-date-picker>
          </en-form-item>
          <en-form-item v-if="form.data.type && ['SRV', 'SAL'].includes(form.data.type.code)" label="减免审批人">
            <en-input
              :model-value="receipt.data.receiptReceivables[0]?.receivable?.adjustingPersonName"
              @change="receipt.adjustingPersonName.change"
            ></en-input>
          </en-form-item>
          <en-form-item v-if="form.data.type && ['SRV', 'SAL', 'POL'].includes(form.data.type.code)" label="实际产值">
            <en-input-number
              :model-value="receipt.data.receiptReceivables[0]?.receivable?.actualOutput"
              @change="receipt.actualOutput.change"
              class="w-full"
            ></en-input-number>
          </en-form-item>
          <en-form-item label="收银备注">
            <en-input v-model="receipt.data.comment"></en-input>
          </en-form-item>
          <en-form-item label="凭证上传">
            <upload-maintain v-model="receipt.data.receiptVoucherImgUrls" multiple :limit="5" folder="receipt"></upload-maintain>
          </en-form-item>
        </en-form>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-table :data="form.receiptOrdersHistory" :height="height" :loading="form.loading">
              <en-table-column label="收款单号" prop="documentSerialNo"></en-table-column>
              <en-table-column label="收银时间">
                <template #default="{ row }: { row: IreceiptOrdersHistory }">{{ formatDate(row.businessDatetime) }} </template>
              </en-table-column>
              <en-table-column label="类型" prop="accountTransactionType.message"></en-table-column>
              <en-table-column label="金额">
                <template #default="{ row }: { row: IreceiptOrdersHistory }">{{ formatMoney(row.amount) }} </template>
              </en-table-column>
              <en-table-column label="收款方式" prop="paymentMethod"></en-table-column>
              <en-table-column label="优惠减免">
                <template #default="{ row }: { row: IreceiptOrdersHistory }">{{ formatMoney(row.badDebt) }} </template>
              </en-table-column>
              <en-table-column label="收银人" prop="preparedBy.name"></en-table-column>
              <en-table-column label="备注" prop="comment"></en-table-column>
            </en-table>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <receivable-dialog v-model="receivableDialog.visible"></receivable-dialog>

  <invioce-detail v-model="invioceDetail.visible" :data="form.data"></invioce-detail>

  <other-detail v-model="otherDetail.visible"></other-detail>

  <history-detail v-model="historyDetail.visible" :data="form.data"></history-detail>

  <batch-detail v-model="batchDetail.visible"></batch-detail>

  <receivable-logs v-model="logs.visible" :data="form.data"></receivable-logs>
  <en-drawer v-model="credit.visible" title="挂账">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" :loading="form.loading">
      <span class="mt-2">客户:{{ data?.payer?.name }}</span>
      <div class="flex items-center gap-6 text-sm mt-5 mb-5">
        <span>应收款:{{ formatMoney(data?.amount) }}</span>
        <span>挂账应收款:{{ formatMoney(data?.amount) }}</span>
        <span>挂账未收款:{{ formatMoney(data?.amount) }}</span>
      </div>
      <en-form-item label="账期">
        <en-date-picker v-model="form.data.creditDueDatetime" class="w-full" value-format="YYYY-MM-DDThh:mm:ss"></en-date-picker>
      </en-form-item>
      <en-form-item label="担保人">
        <select-maintain
          v-model="form.data.creditBy"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['RACB'])
          }"
          :props="{ label: 'name', value: 'name' }"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="挂账日期" disabled>
        <en-date-picker v-model="form.data.creditDatetime" class="w-full" format="YYYY-MM-DD"></en-date-picker>
      </en-form-item>
      <en-form-item label="挂账备注">
        <en-input v-model="form.data.creditComment" class="w-full"></en-input>
      </en-form-item>
      <en-form-item label="实际产值" v-if="form.data.type.code === 'SRV'">
        <en-input-number v-model="form.data.actualOutput" class="w-full"></en-input-number>
      </en-form-item>
    </en-form>
    <template #footer>
      <en-button @click="form.footer.cancel.click">取消</en-button>
      <button-ajax :method="form.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { calculator } from '@enocloud/utils'
import { EnMessageBox } from '@enocloud/components'
import InvioceDetail from '@settlement/components/invioce-detail.vue'

import OtherDetail from '@settlement/components/other-detail.vue'
import BatchDetail from '@settlement/components/batch-detail.vue'

import ReceivableDialog from '@settlement/components/receivable-dialog.vue'
import ReceivableLogs from '@settlement/components/receivable-logs.vue'

type IreceiptOrdersHistory = EnocloudSettlementDefinitions['AccountHistoryDto'] & {
  badDebt: number
}

const receivableInit = (props?: Partial<EnocloudSettlementDefinitions['ReceivableDto']>): EnocloudSettlementDefinitions['ReceivableDto'] => {
  return Object.assign(
    {
      actualOutput: 0,
      adjustingAmount: 0,
      comment: '',
      adjustingPersonName: ''
    },
    props
  ) as any
}

const receiptReceivableInit = (
  props?: Partial<EnocloudSettlementDefinitions['ReceiptReceivableDto']>
): EnocloudSettlementDefinitions['ReceiptReceivableDto'] => {
  return Object.assign(
    {
      amount: 0,
      comment: '',
      receiptAdvanceDeposit: 0,
      receivable: receivableInit()
    },
    props
  ) as any
}

const receiptDataInit = (props?: Partial<EnocloudSettlementDefinitions['ReceiptDto']>): EnocloudSettlementDefinitions['ReceiptDto'] => {
  return Object.assign(
    {
      businessDatetime: '',
      comment: '',
      paymentMethod: '',
      receiptReceivables: [receiptReceivableInit()],
      receiptVoucherImgUrls: []
    },
    props
  ) as any
}

export default factory({
  page: true,

  components: { InvioceDetail, ReceivableDialog, OtherDetail, BatchDetail, ReceivableLogs },

  watch: {
    'form.data': {
      handler() {
        if (this.form.data.id) {
          this.receipt.data.comment = this.form.data.reversedReceiptComment
          this.receipt.data.businessDatetime = this.form.data.reversedReceiptBusinessDatetime || dayjs().format('YYYY-MM-DDTHH:mm:ss')
          this.form.data.creditDatetime = this.form.data.creditDatetime || dayjs().format('YYYY-MM-DDTHH:mm:ss')
          this.receipt.data.payer = this.form.data.payer
          this.receipt.data.paymentMethod = this.form.data.reversedReceiptPaymentMethod

          this.receipt.data.receiptReceivables ??= [receiptReceivableInit()]
          this.receipt.data.receiptReceivables[0].receivable ??= receivableInit()

          this.receipt.data.receiptReceivables[0].amount = calculator.sub(this.form.data.amount ?? 0, this.form.data.receivedAmount ?? 0, 2)
          this.receipt.data.receiptReceivables[0].receivable.id = this.form.data.id
          this.receipt.data.receiptReceivables[0].receivable.receivedAmount = this.form.data.receivedAmount

          if (this.form.data.type?.code === 'SRV') {
            this.receipt.data.receiptReceivables[0].receivable.actualOutput = this.form.data.actualOutput
          }
        }
      },
      deep: true
    }
  },

  config: {
    children: {
      operation: {
        add: {
          click() {
            this.form.init()
            this.detail.visible = true
          },
          command(option: string) {
            switch (option) {
              case 'other':
                this.otherDetail.visible = true
                break

              case 'logs':
                this.logs.visible = true
                break
            }
          }
        },
        batch: {
          click() {
            this.batchDetail.visible = true
          }
        },
        audit: {
          async click() {
            const res = await EnMessageBox.prompt('备注', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' })
            await this.form.audit({ paths: [this.form.data.id], addition: { id: this.form.data.id, comment: res.value } })
            return this.form.get()
          }
        },
        submit: {
          async click() {
            await this.receipt.submit()
            return this.form.get()
          }
        },
        reverse: {
          async click() {
            await this.form.reverse()
            return this.form.get()
          }
        },
        delete: {
          async click() {
            await this.form.delete({ paths: [this.form.data.id] })
            return this.form.get()
          }
        },
        option: {
          async command(option: string) {
            switch (option) {
              case 'invioce':
                this.invioceDetail.visible = true
                break
              case 'credit':
                this.credit.visible = true
                break
              case 'cancelCredit':
                this.operation.reverse.click()
                break
              case 'logs':
                this.logs.visible = true
                break
            }
          }
        }
      },
      manifest: {
        row: {
          click(row: EnocloudSettlementDefinitions['ReceiptDto']) {
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        }
      },
      form: {
        data: {
          quickSearch: '',
          businessEndDate: '',
          businessStartDate: '',
          preparedById: '',
          paymentMethod: '',
          comment: '',
          typeCode: 'REC',
          rsexp: 'id,payer[name],serialNo',
          statusCode: 'P'
        },
        remain: {
          click() {
            this.receipt.data.receiptReceivables[0].receivable.adjustingAmount = Math.floor(
              this.form.summary.unReceivedAmount -
                this.form.summary.service.discountAmount -
                this.receipt.data.receiptReceivables[0]?.amount -
                this.form.summary.service.preAmount -
                this.form.summary.service.discountByCouponAmount -
                this.form.summary.service.discountByRemainAmount
            )
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/settlement/receivable/:receivableId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          refresh: {
            action: 'GET /enocloud/settlement/receivable/query',
            loading: true,
            init: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          creditSubmit: {
            action: 'POST /enocloud/settlement/receivable/credit',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          reverse: {
            action: 'PUT /enocloud/settlement/receivable/:receivableId/reverse',
            loading: true,
            validate: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },

          delete: {
            action: 'DELETE /enocloud/settlement/receivable/:receivableId'
          },
          audit: {
            action: 'POST /enocloud/service/reservation/:serviceReservationId/audit'
          },
          discard: {
            action: 'POST /enocloud/service/reservation/:serviceReservationId/discard'
          }
        },
        footer: {
          cancel: {
            click() {
              this.credit.visible = false
            }
          },
          confirm: {
            async click() {
              await this.form.creditSubmit()
              this.credit.visible = false
              return this.form.get()
            }
          }
        },
        children: {
          maintenances: {
            selection: {
              data: [] as EnocloudSettlementDefinitions['ReceiptDto'][],
              change(rows: EnocloudSettlementDefinitions['ReceiptDto'][]) {
                this.form.maintenances.selection.data = rows
              }
            }
          }
        },
        computed: {
          summary() {
            const { service, policy, topup, sale, type } = this.form.data

            const res = {
              /**
               * 应收金额
               */
              amount: 0,
              /**
               * 已收金额
               */
              receivedAmount: 0,
              /**
               * 未收金额
               */
              unReceivedAmount: 0,

              service: {
                totalAmount: this.form.data.service?.receivableAmountBeforeDiscount,
                amount: this.form.data.service?.receivableAmountBeforeDiscount ?? 0,
                discountAmount: calculator.sub(this.form.data.service?.discountAmount ?? 0, this.form.data.service?.discount ?? 0),
                receivedAmount: service?.receivedAmount ?? 0,
                discountByCouponAmount: this.form.data.service?.couponInstancesAmount ?? 0,
                discountByRemainAmount: this.form.data.service?.chargeableAmount ?? 0,
                preAmount: this.form.data.service?.receiptAdvanceAmount ?? 0,
                freeAmount: this.form.data.service?.discount,
                shouldReceive:
                  Math.floor(
                    (this.form.data.service?.receivableAmountBeforeDiscount -
                      calculator.sub(this.form.data.service?.discountAmount ?? 0, this.form.data.service?.discount ?? 0)) *
                      100
                  ) / 100,
                actualReceive: Math.floor(
                  this.form.data.service?.receivableAmountBeforeDiscount -
                    calculator.sub(this.form.data.service?.discountAmount ?? 0, this.form.data.service?.discount ?? 0)
                )
              },
              sale: {
                amount: 0,
                receivedAmount: 0
              },
              policy: {
                amount: 0,
                receivedAmount: 0
              },
              topup: {
                amount: 0,
                receivedAmount: 0
              }
            }

            switch (type?.code) {
              case 'SRV':
                res.amount = res.service.amount
                res.receivedAmount = res.service.receivedAmount
                res.unReceivedAmount = calculator.sub(res.amount, res.receivedAmount)
                break
              case 'SAL':
                res.amount = res.sale.amount
                res.receivedAmount = res.sale.receivedAmount
                break
              case 'POL':
                res.amount = res.policy.amount
                res.receivedAmount = res.policy.receivedAmount
                break
              case 'TOPUP':
                res.amount = res.topup.amount
                res.receivedAmount = res.topup.receivedAmount
                break
            }

            return res
          },
          disabled() {
            return this.form.data.status?.code === 'F'
          },
          moneyDisabled() {
            return this.form.data.receiptAdvance == 0
          },
          remainingDisabled() {
            return (
              this.form.summary.unReceivedAmount -
                this.form.summary.service.discountAmount -
                this.receipt.data.receiptReceivables[0]?.amount -
                this.form.summary.service.preAmount <
              0
            )
          },
          receiptOrdersHistory() {
            return this.form.data.accountHistories
          }
        }
      },
      credit: {
        visible: false
      },
      detail: {
        visible: true
      },
      dialog: {
        visible: false
      },
      receipt: {
        data: receiptDataInit(),
        ajax: {
          submit: {
            action: 'POST /enocloud/settlement/receipt',
            loading: true,
            params(params) {
              params.payload = this.receipt.data
            }
          }
        },
        children: {
          amount: {
            change(value: number) {
              this.receipt.data.receiptReceivables ??= [receiptReceivableInit()]
              this.receipt.data.receiptReceivables[0].amount = value
            }
          },
          receiptAdvanceDeposit: {
            change(value: number) {
              this.receipt.data.receiptReceivables ??= [receiptReceivableInit()]
              this.receipt.data.receiptReceivables[0].receiptAdvanceDeposit = value
            }
          },
          adjustingAmount: {
            change(value: number) {
              this.receipt.data.receiptReceivables ??= [receiptReceivableInit()]
              this.receipt.data.receiptReceivables[0].receivable ??= receivableInit()
              this.receipt.data.receiptReceivables[0].receivable.adjustingAmount = value
            }
          },

          adjustingPersonName: {
            changes(value: string) {
              this.receipt.data.receiptReceivables ??= [receiptReceivableInit()]
              this.receipt.data.receiptReceivables[0].receivable ??= receivableInit()
              this.receipt.data.receiptReceivables[0].receivable.adjustingPersonName = value
            }
          },
          actualOutput: {
            change(value: number) {
              this.receipt.data.receiptReceivables ??= [receiptReceivableInit()]
              this.receipt.data.receiptReceivables[0].receivable ??= receivableInit()
              this.receipt.data.receiptReceivables[0].receivable.actualOutput = value
            }
          }
        }
      },
      receivableDialog: {
        visible: false
      },
      invioceDetail: {
        visible: false
      },
      creditDetail: {
        visible: false
      },
      historyDetail: {
        visible: false
      },
      otherDetail: {
        visible: false
      },
      batchDetail: {
        visible: false
      },
      logs: {
        visible: false
      }
    }
  }
})
</script>
